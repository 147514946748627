// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bijles-tsx": () => import("./../../../src/pages/bijles.tsx" /* webpackChunkName: "component---src-pages-bijles-tsx" */),
  "component---src-pages-cito-training-tsx": () => import("./../../../src/pages/cito-training.tsx" /* webpackChunkName: "component---src-pages-cito-training-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-huiswerkbegeleiding-tsx": () => import("./../../../src/pages/huiswerkbegeleiding.tsx" /* webpackChunkName: "component---src-pages-huiswerkbegeleiding-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-remedial-teaching-tsx": () => import("./../../../src/pages/remedial-teaching.tsx" /* webpackChunkName: "component---src-pages-remedial-teaching-tsx" */),
  "component---src-pages-tarieven-tsx": () => import("./../../../src/pages/tarieven.tsx" /* webpackChunkName: "component---src-pages-tarieven-tsx" */),
  "component---src-pages-voorstellen-tsx": () => import("./../../../src/pages/voorstellen.tsx" /* webpackChunkName: "component---src-pages-voorstellen-tsx" */)
}

